export const Paths = {
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  Home: '/',
  TaskList: '/tasks',
  TaskDetail: '/tasks/:id',
  Settings: '/settings',
  Tags: '/tags',
  TagDetail: '/tags/:id',
};
