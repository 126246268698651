import { useMutation } from '@apollo/client';
import { Button, Stack, Text } from '@mantine/core';
import { DELETE_ACCOUNT } from '../../../../api/mutations';
import SectionHeader from '../../../../components/page/SectionHeader';
import { openConfirmModal } from '@mantine/modals';
import useAuthContext from '../../../../hooks/useAuthContext';

const AccountPage = () => {
  const { signOut, userDetails } = useAuthContext();

  const [deleteAccountMutation, { loading: deleting }] =
    useMutation(DELETE_ACCOUNT);

  // @ts-ignore
  const accountId = userDetails?.account;

  const handleConfirm = () => {
    deleteAccountMutation({
      variables: {
        id: accountId,
      },
    })
      .then(({ data }) => {
        signOut();
      })
      .catch((e) => {
        console.log('Error');
      });
  };

  const onDelete = () => {
    openConfirmModal({
      title: 'Delete Account',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this account? All data, team members
          and projects will be permanently removed.
        </Text>
      ),
      labels: { confirm: 'Delete Account', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleConfirm(),
    });
  };

  return (
    <>
      <SectionHeader title="Account" description="Delete your account here" />
      <Stack py="lg" justify="left" align="flex-start">
        <Button color="red" onClick={() => onDelete()}>
          Delete account
        </Button>
      </Stack>
    </>
  );
};

export default AccountPage;
