import { useMutation, useQuery } from '@apollo/client';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Drawer,
  Group,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconSearch, IconTrash } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  BULK_DELETE_TAGS,
  MUTATION_DELETE_TAG,
  MUTATION_TOGGLE_TASK_STATUS,
} from '../../../api/mutations';
import { QUERY_GET_TAGS_LIST, QUERY_GET_TASKS } from '../../../api/queries';
import { FORM_MODE } from '../../../components/forms/FormBase';
import Page from '../../../components/page/Page';
import DateService from '../../../utils/TimeService';
import TagForm from './TagForm';

export default function TagsListPage() {
  const listLength = 8;

  const navigate = useNavigate();

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebouncedState(
    '',
    200
  );

  const initialSearchState = {
    currentPage: 1,
    itemsPerPage: listLength,
    skip: 0,
    startCursor: undefined,
    endCursor: undefined,
    sortStatus: {
      direction: 'desc',
      columnAccessor: 'createdAt',
    },
    tagSelection: [],
    searchQuery: '',
  };

  useEffect(() => {
    setSearchState((prevState) => ({
      ...prevState,
      searchQuery: debouncedSearchQuery,
    }));
  }, [debouncedSearchQuery]);

  const [searchState, setSearchState] = useState(initialSearchState);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<any>([]);

  const {
    loading: fetchingTasks,
    error: errorFetchingTasks,
    data: tags,
    refetch: refetchTags,
  } = useQuery(QUERY_GET_TAGS_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: listLength,
      after: undefined,
      before: undefined,
      skip: 0,
      query: '',
      last: undefined,
      orderBy: {
        direction: initialSearchState.sortStatus.direction,
        field: initialSearchState.sortStatus.columnAccessor,
      },
    },
    onCompleted: (data) => {
      setSearchState({
        ...searchState,
        startCursor: data.getTagsList.pageInfo.startCursor,
        endCursor: data.getTagsList.pageInfo.endCursor,
      });
    },
  });

  const [deleteTagMutation, { loading: deleting }] = useMutation(
    MUTATION_DELETE_TAG,
    {
      refetchQueries: [
        {
          query: QUERY_GET_TAGS_LIST,
        },
      ],
    }
  );

  useEffect(() => {
    const { searchQuery, itemsPerPage } = searchState;

    const queryVariables = {
      first: itemsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
      query: searchQuery,
      orderBy: {
        direction: searchState.sortStatus.direction,
        field: searchState.sortStatus.columnAccessor,
      },
    };

    refetchTags(queryVariables);

    // @ts-ignore
    setSearchState((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  }, [searchState.searchQuery]);

  const [toggleCampaignStatus, { loading: toggling }] = useMutation(
    MUTATION_TOGGLE_TASK_STATUS,
    {
      refetchQueries: [{ query: QUERY_GET_TASKS }],
    }
  );

  const [bulkDeleteMutation, { loading: bulkDeleting }] = useMutation(
    BULK_DELETE_TAGS,
    {
      refetchQueries: [{ query: QUERY_GET_TAGS_LIST }],
    }
  );

  const handleBulkDelete = async (selection: any) => {
    try {
      await bulkDeleteMutation({
        variables: {
          ids: selection.map((item: any) => item.id),
        },
      });

      await reloadTable();

      setSearchState((prevState) => ({
        ...prevState,
        currentPage: 1,
        tagSelection: [],
      }));

      setSelectedRecords([]);
      showNotification({
        title: `Deleted tasks`,
        message: '🤥',
      });
    } catch (e) {
      console.log('Error');
    }
  };

  const onBulkDelete = () => {
    openConfirmModal({
      title: 'Delete tags',
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete these tags?</Text>
      ),
      labels: { confirm: 'Delete tags', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleBulkDelete(selectedRecords),
    });
  };

  const handleSortStatusChange = async (sortStatus: any) => {
    const { itemsPerPage, searchQuery } = searchState;

    const queryVariables = {
      first: itemsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
      query: searchQuery,
      orderBy: {
        direction: sortStatus.direction,
        field: sortStatus.columnAccessor,
      },
    };

    refetchTags(queryVariables);

    setSearchState((prevState) => ({
      ...prevState,
      sortStatus,
      currentPage: 1,
    }));
  };

  const handlePaginationChange = async (targetPage: any) => {
    const { currentPage, searchQuery, itemsPerPage, endCursor, startCursor } =
      searchState;

    if (targetPage === currentPage) {
      return;
    }

    let paginationState = {};

    if (targetPage > currentPage) {
      // @ts-ignore
      paginationState.skip = itemsPerPage * (targetPage - 1 - currentPage);
      // Paginate forward
      // @ts-ignore
      paginationState.first = itemsPerPage;
      // @ts-ignore
      paginationState.after = endCursor;

      // @ts-ignore
      paginationState.last = undefined;
      // @ts-ignore
      paginationState.before = undefined;
    } else if (targetPage < currentPage) {
      // @ts-ignore
      paginationState.skip = itemsPerPage * (currentPage - (targetPage + 1));

      // Paginate backward
      // @ts-ignore
      paginationState.after = undefined;
      // @ts-ignore
      paginationState.first = undefined;

      // @ts-ignore
      paginationState.last = itemsPerPage;
      // @ts-ignore
      paginationState.before = startCursor;
    }

    const queryVariables = {
      ...paginationState,
      orderBy: {
        direction: searchState.sortStatus.direction,
        field: searchState.sortStatus.columnAccessor,
      },
      searchQuery,
    };

    try {
      await refetchTags(queryVariables);

      setSearchState((prevState) => ({
        ...prevState,
        currentPage: targetPage,
      }));
    } catch (e) {
      console.log('Error');
    }
  };

  const reloadTable = async () => {
    await refetchTags({
      first: listLength,
      after: undefined,
      before: undefined,
      skip: 0,
      query: '',
      last: undefined,
    });
  };

  const handleConfirmDeleteTag = async (tagId: any) => {
    try {
      const { data } = await deleteTagMutation({
        variables: {
          id: tagId,
        },
      });

      //navigate('/todos', { replace: true });
      // showNotification({
      //   title: `Deleted tag: ${data.deleteTag.name}`,
      //   message: '🤥',
      // });

      await refetchTags({
        first: listLength,
        after: undefined,
        before: undefined,
        skip: 0,
        query: '',
        last: undefined,
      });
      setSearchState((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    } catch (e) {
      console.log('Error');
    }
  };

  const onCreateTag = ({ createTag }: any) => {
    const { name, id } = createTag;
    refetchTags();
    navigate(`/tags/${id}`);
    setDrawerIsOpen(false);
    showNotification({
      title: `Created tag: ${name}  `,
      message: '🤥',
    });
  };

  const onDeleteTag = (tagId: string) => {
    openConfirmModal({
      title: 'Delete Tag',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this tag? This action is destructive.
        </Text>
      ),
      labels: { confirm: 'Delete tag', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleConfirmDeleteTag(tagId),
    });
  };

  const onSelectedRecordsChange = (records: any) => {
    setSelectedRecords(records);
  };
  return (
    <Page
      title="Tags"
      primaryAction={{
        content: 'Create tag',
        onClick: () => setDrawerIsOpen(true),
        loading: fetchingTasks,
      }}
    >
      <Box
        p={'md'}
        sx={(theme) => ({
          borderRadius: 8,
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[7]
              : theme.colors.gray[1],
        })}
        mb={8}
      >
        <Group spacing={'lg'}>
          <TextInput
            label="Search"
            w={300}
            placeholder="Search for title or description"
            icon={<IconSearch size={16} />}
            defaultValue={searchState.searchQuery}
            onChange={(e) => setDebouncedSearchQuery(e.target.value)}
          />
        </Group>
      </Box>
      <Box pos={'relative'}>
        {/* <LoadingOverlay visible={fetchingTasks} /> */}
        {selectedRecords.length > 0 && (
          <Box
            pos={'absolute'}
            sx={(theme) => ({
              zIndex: 10,
              background:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[7]
                  : theme.white,
            })}
            top={-3}
            right={0}
            p={4}
          >
            <Group position="right" spacing={'xs'}>
              <Text fz={'sm'} fw={'bold'}>
                {selectedRecords.length} selected
              </Text>

              <Button
                size="xs"
                variant="outline"
                onClick={() => onBulkDelete()}
                leftIcon={<IconTrash size="1rem" stroke={1.5} />}
              >
                Delete
              </Button>
            </Group>
          </Box>
        )}
        <Box style={{ zIndex: 0 }}>
          <DataTable
            // withBorder
            minHeight={200}
            noRecordsText="No tags found"
            records={tags?.getTags?.edges.map((edge: any) => edge.node)}
            fetching={fetchingTasks}
            onSortStatusChange={handleSortStatusChange}
            /**  @ts-ignore */
            sortStatus={searchState.sortStatus}
            columns={[
              {
                accessor: 'name',
                width: 200,
                title: 'Name',
                render: (item: any) => {
                  return (
                    <Link to={`/tags/${item.id}`} color={'blue'}>
                      {item.name}
                    </Link>
                  );
                },
              },

              {
                accessor: 'campaigns',
                title: 'Tasks',
                render: (item: any) => {
                  return <Badge>{item.campaigns.length}</Badge>;
                },
              },
              {
                accessor: 'createdAt',
                title: 'Created At',
                sortable: true,
                render: (item: any) => {
                  return (
                    <Text>
                      {DateService.getReadableDateTimeInNearPast(
                        item.createdAt
                      )}
                    </Text>
                  );
                },
              },

              {
                accessor: 'actions',
                title: 'Actions',
                textAlignment: 'right',
                render: (task: any) => (
                  <Group spacing={4} position="right" noWrap>
                    <ActionIcon
                      color="gray"
                      onClick={() => onDeleteTag(task.id)}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>
                ),
              },
            ]}
            totalRecords={tags?.getTags?.totalCount}
            recordsPerPage={searchState.itemsPerPage}
            page={searchState.currentPage}
            onPageChange={handlePaginationChange}
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={onSelectedRecordsChange}
          />
        </Box>
      </Box>

      <Drawer
        opened={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        title="Create tag"
        padding="xl"
        size="sm"
        position="right"
      >
        <TagForm mode={FORM_MODE.CREATE} onSubmit={onCreateTag} />
      </Drawer>
    </Page>
  );
}
