import React, { useState } from 'react';
import { Flex, Title, Text, Button, Divider } from '@mantine/core'; // Replace with your component library imports

interface SectionHeaderProps {
  title: string;
  description: string;
  actions?: React.ReactNode | null;
  hasDivider?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  description,
  actions,
  hasDivider = true,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Flex py="xl" direction="column" align="flex-start">
        <Title size="h3">{title}</Title>
        <Text size="md" color="gray">
          {description}
        </Text>
        {actions && (
          <Flex direction="row" align="center">
            {actions}
          </Flex>
        )}
      </Flex>
      {hasDivider && <Divider size="xs" />}
    </>
  );
};

export default SectionHeader;
