import { Button, Group, Stack, TextInput } from '@mantine/core';
import { QUERY_GET_TAG_DETAIL } from '../../../api/queries';
import FormBase, { FORM_MODE } from '../../../components/forms/FormBase';
import * as Yup from 'yup';
import { useCreateTagMutation } from '../../../hooks/useCreateTagMutation';
import { useUpdateTagMutation } from '../../../hooks/useUpdateTagMutation';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a title'),
});

const TagForm = ({ mode, entityId, onSubmit }: any) => {
  return (
    <FormBase
      loader={QUERY_GET_TAG_DETAIL}
      queryVariables={{
        id: entityId,
      }}
      formatInitialValues={(data: any) => {
        return {
          ...data?.tag,
        };
      }}
      onSubmit={onSubmit}
      submitAction={
        mode === FORM_MODE.CREATE ? useCreateTagMutation : useUpdateTagMutation
      }
      mode={mode}
      validationSchema={validationSchema}
    >
      {(form: any) => (
        <>
          <Stack>
            <TextInput
              required
              label="Name"
              placeholder="Enter the name of the tag"
              {...form.getInputProps('name')}
            />
          </Stack>
          <Group position="right" mt="xl">
            <Button disabled={false} type="submit">
              {mode === FORM_MODE.CREATE ? 'Create tag' : 'Save changes'}
            </Button>
          </Group>
        </>
      )}
    </FormBase>
  );
};

export default TagForm;
