import { useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Group,
  List,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MUTATION_GET_CHECKOUT_URL,
  MUTATION_GET_CUSTOMER_PORTAL_URL,
} from '../../../../api/mutations';
import { QUERY_GET_ACTIVE_SUBSCRIPTION_AND_AVAILABLE_PLANS } from '../../../../api/queries';
import SectionHeader from '../../../../components/page/SectionHeader';
import DateService from '../../../../utils/TimeService';

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const { tabValue } = useParams();

  const { loading, error, data, refetch } = useQuery(
    QUERY_GET_ACTIVE_SUBSCRIPTION_AND_AVAILABLE_PLANS,
    {
      // pollInterval: 500,
    }
  );
  const [getCustomerPortalUrl, { loading: deleting }] = useMutation(
    MUTATION_GET_CUSTOMER_PORTAL_URL
  );

  const [getCheckoutUrl, { loading: del }] = useMutation(
    MUTATION_GET_CHECKOUT_URL
  );

  const onGetCheckoutUrl = (productId: any) => {
    getCheckoutUrl({
      variables: {
        productId,
      },
    })
      .then(({ data }) => {
        window.location.href = data.requestCheckoutUrl;
      })
      .catch((e) => {
        console.log('Error');
      });
  };

  const onGetCustomerPortalUrl = () => {
    getCustomerPortalUrl()
      .then(({ data }) => {
        window.location.href = data.requestCustomerPortalUrl;
      })
      .catch((e) => {
        console.log('Error');
      });
  };

  const { getActiveSubscription, getAvailablePlans } = data || {};

  const handleUpgrade = (productId: any) => {
    onGetCheckoutUrl(productId);
  };

  const isFreePlan = (planProductId: string) => {
    return planProductId === 'free';
  };

  const isCurrentPlan = (planProductId: string) => {
    const a = planProductId === getActiveSubscription?.stripeProductId;
    return a;
  };

  const hasSubscription = () => {
    return getActiveSubscription !== null;
  };

  const availablePlans = getAvailablePlans?.map((plan: any) => {
    const { currency, interval, productName, productId, price } = plan;
    const { stripeCancelAtPeriodEnd, stripeCurrentPeriodEnd, stripeCancelAt } =
      getActiveSubscription || {};
    const isCanceled = stripeCancelAtPeriodEnd;

    return (
      <Card mih={150} shadow="sm" radius="md" withBorder>
        <Stack spacing={'sm'} align="flex-start">
          <Box>
            {isCurrentPlan(productId) && (
              <Badge mb="xs" variant="light">
                Current plan {isCanceled && `(Canceled)`}
              </Badge>
            )}
            {!hasSubscription() && isFreePlan(productId) && (
              <Badge mb="xs" variant="light">
                Current plan
              </Badge>
            )}
            <Box>
              <Text fz={'sm'} fw="bold">
                {productName}
              </Text>
              <Text fz="lg" fw="bold">
                {price} {currency.toUpperCase()}{' '}
                <Text span color="gray">
                  {' '}
                  per {interval.charAt(0).toUpperCase() + interval.slice(1)}
                </Text>
              </Text>

              {isCurrentPlan(productId) && isCanceled && (
                <Text fz="sm">
                  Your plan will be canceled on{' '}
                  {DateService.getReadableDate(stripeCancelAt)}{' '}
                </Text>
              )}

              {isCurrentPlan(productId) && !isCanceled && (
                <Text fz="sm">
                  Your plan automatically renews on{' '}
                  {DateService.getReadableDate(stripeCurrentPeriodEnd)}{' '}
                </Text>
              )}
            </Box>
          </Box>
          {!hasSubscription() && !isFreePlan(productId) && (
            <Button onClick={() => handleUpgrade(productId)}>Upgrade</Button>
          )}
        </Stack>
        <Divider my="sm" />
        <Box>
          <List
            spacing="xs"
            size="sm"
            center
            icon={<IconCircleCheck color="green" size="1.25rem" />}
          >
            <List.Item> Incredible feature 1</List.Item>
            <List.Item>Incredible feature 2</List.Item>
            <List.Item> Incredible feature 3</List.Item>
            {isFreePlan(productId) ? (
              <List.Item icon={<IconCircleX size="1.25rem" color="red" />}>
                Not Incredible feature 4
              </List.Item>
            ) : (
              <List.Item> Incredible feature 4</List.Item>
            )}
          </List>
        </Box>
      </Card>
    );
  });

  return (
    <>
      <SectionHeader
        title="Subscription"
        description="Manage your subscription and billing information below."
      />
      <Box pos={'relative'}>
        <LoadingOverlay visible={loading} overlayBlur={1} />

        <Group
          pos={'relative'}
          grow
          mb="lg"
          py="xl"
          position="left"
          align="flex-start"
        >
          {availablePlans}
        </Group>

        {hasSubscription() && (
          <Stack spacing={'xs'} align="flex-start">
            <Button onClick={onGetCustomerPortalUrl}>
              Manage subscription
            </Button>
            <Text color="gray" fz={'sm'}>
              You will be redirected to the stripe customer portal.
            </Text>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default SubscriptionPage;
