import React, { useState, useEffect } from 'react';
import { MultiSelect, Loader } from '@mantine/core';
import { useQuery, useMutation } from '@apollo/client';

interface AsyncMultiselectProps<T> {
  loaderQuery: any;
  createMutation: any;
  selectedValues: T[];
  creatable?: boolean;
  clearable?: boolean;
  loaderQueryVariables?: any;
  label: string;
  entityId: string;
  placeholder?: string;
  formatData: (data: any) => T[];
  onChange: (newValues: T[]) => void;
  renderOption?: (option: T) => React.ReactNode;
}

const AsyncMultiselect = <T extends any>({
  loaderQuery,
  createMutation,
  entityId,
  loaderQueryVariables,
  selectedValues,
  clearable,
  label,
  formatData,
  creatable,
  placeholder,
  onChange,
  renderOption = (option: T) => String(option),
  ...otherProps
}: AsyncMultiselectProps<T>) => {
  const [options, setOptions] = useState<T[]>([]);
  const {
    loading: loadingOptions,
    data,
    refetch,
  } = useQuery(loaderQuery, {
    fetchPolicy: 'network-only',
    ...loaderQueryVariables,
  });

  const [createItemMutation, { loading: creatingItem }] =
    useMutation(createMutation);

  const _internalOnChange = (newValues: T[]) => {
    // check if is set and array include undefined
    // @ts-ignore
    if (newValues && newValues.includes(undefined)) {
      return;
    }

    onChange(newValues);
  };

  useEffect(() => {
    if (data) {
      const formatedData = formatData(data);
      setOptions(formatedData);
    }
  }, [data]);

  const handleCreateItem = async (newItem: string) => {
    try {
      const trimmedItem = newItem.trim();
      if (!trimmedItem) {
        throw new Error('Item name cannot be empty.');
      }

      const { data } = await createItemMutation({
        variables: { name: trimmedItem },
      });

      const updatedValues = !selectedValues
        ? [data[entityId].id]
        : [...selectedValues, data[entityId].id];

      onChange(updatedValues);

      await refetch();

      return {
        value: data.createTag.id,
        label: data.createTag.name,
      };
    } catch (error) {
      console.error('Error creating item:', error);
    }
  };

  return (
    <MultiSelect
      /** @ts-ignore  */
      data={options}
      getCreateLabel={(query) => `+ Create ${query}`}
      /** @ts-ignore  */
      value={selectedValues}
      /** @ts-ignore  */
      onChange={_internalOnChange}
      placeholder={placeholder}
      label={label}
      clearSearchOnChange
      searchable
      clearable={clearable}
      creatable={creatable}
      rightSection={
        loadingOptions || creatingItem ? <Loader size={16} /> : null
      }
      itemRenderer={(option: any) => renderOption(option)}
      /** @ts-ignore  */
      onCreate={(newItem) => handleCreateItem(newItem)}
      {...otherProps}
    />
  );
};

export default AsyncMultiselect;
