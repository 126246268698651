import { Code } from '@mantine/core';

const Debug = (values: any) => (
  <>
    <Code block mt={5}>
      {JSON.stringify(values, null, 4)}
    </Code>
  </>
);

export default Debug;
