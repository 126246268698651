import { ApolloError } from '@apollo/client';
import { Box, Button, Paper, Space, Text } from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import ResetPasswordForm from '../../components/forms/ResetPasswordForm';
import {
  ResetPasswordDto,
  useResetPasswordMutation,
} from '../../hooks/useResetPasswordMutation';
import { Paths } from '../../routes/paths';
import ErrorService from '../../services/ErrorService';

const ForgotPasswordPage = () => {
  const [resetPassword, { loading }] = useResetPasswordMutation();
  const [formSubmitErrors, setFormSubmitErrors] = useState({});
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onSubmit = ({ password }: { password: string }) => {
    if (!token) {
      return;
    }

    const data: ResetPasswordDto = {
      password,
      token,
    };

    resetPassword(data)
      .then(() => {
        setPasswordResetSuccessful(true);
      })
      .catch((e: ApolloError) => {
        setFormSubmitErrors(ErrorService.getSubmitErrors(e));
      });
  };

  return (
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 420,
        paddingTop: 40,
      }}
    >
      <Paper radius="md" p="xl" withBorder>
        {passwordResetSuccessful ? (
          <>
            <Text>
              Done! You can <Link to={Paths.SignIn}>sign in</Link> now with your
              new password.
            </Text>
          </>
        ) : (
          <ResetPasswordForm
            formSubmitErrors={formSubmitErrors}
            isSubmitting={loading}
            onSubmit={onSubmit}
          />
        )}
      </Paper>
    </Box>
  );
};

export default ForgotPasswordPage;
