import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_TAG } from '../api/mutations';

export const useCreateTagMutation = (): Array<any> => {
  const [createTagMutation, results] = useMutation(MUTATION_CREATE_TAG, {});

  const createTag = (data: any) => {
    return createTagMutation({
      variables: {
        name: data.name,
      },
    });
  };

  return [createTag, results];
};
