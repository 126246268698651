import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Title,
} from '@mantine/core';

type PrimaryActionProps = {
  content: string;
  onClick: () => void;
  loading?: boolean;
  destructive?: boolean;
};

type PageProps = {
  children: React.ReactNode;
  primaryAction?: PrimaryActionProps;
  title: string;
  breadcrumbs?: React.ReactNode;
  showLoadingOverlay?: boolean;
  secondaryActions?: [PrimaryActionProps];
};

// add missing types
const Page = ({
  children,
  primaryAction,
  showLoadingOverlay,
  secondaryActions,
  title,
  breadcrumbs,
}: PageProps) => {
  const { content, onClick, loading } = primaryAction || {};

  if (showLoadingOverlay) {
    return (
      <Box h="100%" pos={'relative'}>
        <LoadingOverlay visible overlayBlur={1} />
      </Box>
    );
  }

  return (
    <Box pos={'relative'}>
      <Box>
        <Group position="apart">
          <Box>
            <Stack spacing="xs">
              <Title fz={'h2'}>{title}</Title>
            </Stack>
          </Box>
          <Group position="apart">
            {secondaryActions?.map((action) => (
              <Button
                variant="light"
                loading={action.loading}
                onClick={action.onClick}
                color={action.destructive ? 'red' : 'gray'}
              >
                {action.content}
              </Button>
            ))}
            {primaryAction && (
              <Button loading={loading} onClick={onClick}>
                {content}
              </Button>
            )}
          </Group>
        </Group>
      </Box>
      <Box pt={'xl'}>{children}</Box>
    </Box>
  );
};

export default Page;
