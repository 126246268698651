import { showNotification } from '@mantine/notifications';
import { FORM_MODE } from '../../../../components/forms/FormBase';
import SectionHeader from '../../../../components/page/SectionHeader';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePasswordPage = () => {
  return (
    <>
      <SectionHeader
        title="Password"
        description="Update your password here."
      />
      <ChangePasswordForm
        onSubmit={() => {
          showNotification({
            title: 'Password changed!',
            message: 'Hey there, your code is awesome! ',
          });
        }}
      />
    </>
  );
};

export default ChangePasswordPage;
