import {
  Button,
  Group,
  Paper,
  PasswordInput,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Paths } from '../../routes/paths';
import FormBase, { FORM_MODE } from './FormBase';
import { useAcceptInvitationMutation } from '../../hooks/useAcceptInvitationMutation';
import { useSignUpMutation } from '../../hooks/useSignUpMutation';

const validationSchema = Yup.object().shape({
  //email: Yup.string().email('Please enter a valid email address.'),
  // password: Yup.string().min(
  //   6,
  //   'Your password should have at least 6 characters.'
  // ),
  // organization: Yup.string(),
});

export type SignUpFormValues = {
  owner: {
    email: string;
    password: string;
  };
  organization: {
    name: string;
  };
};

type SignUpFormProps = {
  token?: string | undefined;
  invitational: boolean;
  email?: string | undefined;
};

const SignUpForm = ({ invitational, token, email }: SignUpFormProps) => {
  return (
    <Paper radius="md" p="xl" withBorder>
      <Title order={1} size="h3">
        Sign up and get started
      </Title>
      <Space h="md" />

      <FormBase
        initialValues={{
          owner: {
            email: email || '',
            password: '',
          },
          organization: {
            name: '',
          },
        }}
        formatInitialValues={(data: any) => {
          return data;
        }}
        formatPreSubmit={(data: any) => {
          if (invitational) {
            return {
              user: {
                ...data.owner,
              },
              token,
            };
          }
          return data;
        }}
        submitAction={token ? useAcceptInvitationMutation : useSignUpMutation}
        validationSchema={validationSchema}
        mode={FORM_MODE.CREATE}
      >
        {(form: any) => (
          <>
            <Stack>
              <TextInput
                required
                label="Email"
                placeholder="Enter your email address"
                {...form.getInputProps('owner.email')}
              />
              <PasswordInput
                required
                label="Password"
                description="Use 8 or more characters with a mix of letters, numbers and symbols"
                placeholder="Enter your password"
                {...form.getInputProps('owner.password')}
              />
              {!invitational && (
                <TextInput
                  required
                  label="What is the name of your organization?"
                  placeholder="Enter the name of your organization"
                  {...form.getInputProps('organization.name')}
                />
              )}
            </Stack>
            <Group position="apart" mt="xl">
              {!invitational && (
                <Text color="dimmed" size="xs">
                  <Link to={Paths.SignIn}>
                    Already have an account? Sign in!
                  </Link>
                </Text>
              )}
              <Button disabled={form.isSubmitting} type="submit">
                Sign up
              </Button>
            </Group>
          </>
        )}
      </FormBase>
    </Paper>
  );
};

export default SignUpForm;
