import { showNotification } from '@mantine/notifications';
import SectionHeader from '../../../../components/page/SectionHeader';
import ProfileForm from './ProfileForm';

const ProfilePage = () => {
  return (
    <>
      <SectionHeader
        title="Profile"
        description="Update your profile information"
      />
      <ProfileForm
        onSubmit={() => {
          showNotification({
            title: 'Settings updated 🤥',
            message: 'Hey there, your code is awesome! ',
          });
        }}
      />
    </>
  );
};

export default ProfilePage;
