import { ActionIcon, Box, Group, Table, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import DateService from '../../../../utils/TimeService';
import { formatUserRole } from '../../../../utils/utils';

// add typescript props interface
const PendingInvitationsList = ({
  members: invitations,
  onDeleteInvitation,
}: any) => {
  if (!invitations) {
    return null;
  }
  const ths = (
    <tr>
      <th>Email</th>
      <th>Role</th>
      <th>Sent</th>
      <th></th>
    </tr>
  );

  const rows = invitations.map((invitation: any) => (
    <tr key={invitation.email}>
      <td width={300}>{invitation.email}</td>
      <td>{formatUserRole(invitation.role)}</td>
      <td>{DateService.getReadableDateTimeInNearPast(invitation.createdAt)}</td>
      <td>
        <Group spacing={0} position="right">
          <ActionIcon>
            <IconTrash
              onClick={() => onDeleteInvitation(invitation.id)}
              size="1rem"
              stroke={1.5}
              color="black"
            />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ));

  return (
    <Table captionSide="bottom">
      <thead>{ths}</thead>
      <tbody>
        {rows.length ? (
          rows
        ) : (
          <Box p={8}>
            <Text color="grey" fw={400} fz="sm">
              No invitations yet.
            </Text>
          </Box>
        )}
      </tbody>
    </Table>
  );
};

export default PendingInvitationsList;
