import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Flex,
  Loader,
  LoadingOverlay,
  Text,
  Title,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import {
  MUTATION_DELETE_INVITATION,
  MUTATION_DELETE_TEAM_MEMBER,
} from '../../../../api/mutations';
import { QUERY_TEAM_MEMBERS_AND_INVITATIONS } from '../../../../api/queries';
import Section from '../../../../components/page/Section';
import InviteTeamMemberForm from './InviteTeamMemberForm';
import PendingInvitationsList from './PendingInvitationsList';
import TeamMembersList from './TeamMembersList';

const TeamPage = () => {
  const [opened, setOpened] = useState(false);

  const [deleteInvitationMutation, { loading: deletingInvitation }] =
    useMutation(MUTATION_DELETE_INVITATION, {
      refetchQueries: [{ query: QUERY_TEAM_MEMBERS_AND_INVITATIONS }],
    });

  const [deleteMemberMutation, { loading: deletingMember }] = useMutation(
    MUTATION_DELETE_TEAM_MEMBER,
    {
      refetchQueries: [{ query: QUERY_TEAM_MEMBERS_AND_INVITATIONS }],
    }
  );

  const { loading, error, data, refetch } = useQuery(
    QUERY_TEAM_MEMBERS_AND_INVITATIONS,
    {
      // pollInterval: 500,
    }
  );

  const onDeleteInvitation = (invitationId: string) => {
    openConfirmModal({
      title: 'Delete Invitation',
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this invitation?</Text>
      ),
      labels: { confirm: 'Delete invitation', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => confirmDeletionOfInvitation(invitationId),
    });
  };

  const confirmDeletionOfInvitation = (invitationId: any) => {
    deleteInvitationMutation({
      variables: {
        id: invitationId,
      },
    })
      .then(({ data }) => {
        //navigate('/todos', { replace: true });
        showNotification({
          title: `Deleted invitation of ${data.deleteInvitation.email}`,
          message: '🤥',
        });
      })
      .catch((e) => {
        console.log('Error');
      });
  };

  const onDeleteTeamMember = (memberId: string) => {
    openConfirmModal({
      title: 'Delete Member',
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this member?</Text>
      ),
      labels: { confirm: 'Delete member', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => confirmDeletionOfTeamMember(memberId),
    });
  };

  const confirmDeletionOfTeamMember = (memberId: any) => {
    deleteMemberMutation({
      variables: {
        id: memberId,
      },
    })
      .then(({ data }) => {
        //navigate('/todos', { replace: true });
        showNotification({
          title: `Deleted member ${data.deleteTeamMember.email}`,
          message: '🤥',
        });
      })
      .catch((e) => {
        console.log('Error');
      });
  };

  const onSuccess = () => {
    refetch();
    setOpened(false);
    showNotification({
      title: 'Team member invited!',
      message: 'Let´s see if they join! ',
    });
  };

  return (
    <>
      <Flex
        // mih={100}
        justify="space-between"
        py={'lg'}
        align="center"
        direction="row"
        wrap="wrap"
      >
        <Flex direction="column" align="flex-start">
          <Title size={'h3'}>Team management</Title>
          <Text size="md" color="gray">
            Manage your team members and invite new users.
          </Text>
        </Flex>
        <Button onClick={() => setOpened(true)}>Invite more users</Button>
      </Flex>
      <Divider size="xs" />
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Invite users"
        padding="md"
        size="sm"
        position="right"
      >
        <InviteTeamMemberForm onSubmit={onSuccess} />
      </Drawer>

      <Box pos={'relative'}>
        <LoadingOverlay visible={loading} overlayBlur={1} />
        <Section
          heading="Team members"
          description="Your current team members are listed here."
        >
          {deletingMember ? (
            <Loader />
          ) : (
            <TeamMembersList
              onDeleteTeamMember={onDeleteTeamMember}
              members={data?.getMembers}
            />
          )}
        </Section>

        <Section
          heading="Pending invitations"
          description="Invited users are listed here."
        >
          {deletingInvitation ? (
            <Loader />
          ) : (
            <PendingInvitationsList
              onDeleteInvitation={onDeleteInvitation}
              members={data?.getInvitations}
            />
          )}
        </Section>
      </Box>
    </>
  );
};

export default TeamPage;
