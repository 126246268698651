import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    Observable,
    gql,
    makeVar
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Storage from '../services/Storage';
import { refreshAccessToken } from './refreshAccessToken';

export const reSignIn = makeVar<boolean>(false);

export const RESIGN_IN = gql`
    query ShouldReSignIn {
        reSignIn @client
    }
`;

const getAuthorizationBearer = (token: string | null): string | undefined => {
    if (!token) { return undefined }
    return `Bearer ${token}`;
}

const API_URL = `${process.env.REACT_APP_API_URL}/graphql`;

const httpLink = new HttpLink({
    uri: API_URL,
    credentials: 'include'
});


const refreshTokenLink = onError(
    ({ response, graphQLErrors, networkError, operation, forward }) => {

        if (graphQLErrors) {
            for (let error of graphQLErrors) {
                const { extensions } = error;
                // @ts-ignore
                switch (extensions.code) {
                    case 'auth_access_token_expired':
                        return new Observable(observer => {
                            refreshAccessToken()
                                .then(accessToken => {
                                    const oldHeaders = operation.getContext()
                                        .headers;
                                    operation.setContext({
                                        headers: {
                                            ...oldHeaders,
                                            authorization: getAuthorizationBearer(
                                                accessToken
                                            )
                                        }
                                    });
                                })
                                .then(() => {
                                    const subscriber = {
                                        next: observer.next.bind(observer),
                                        error: observer.error.bind(observer),
                                        complete: observer.complete.bind(
                                            observer
                                        )
                                    };

                                    // Retry last failed request
                                    forward(operation).subscribe(subscriber);
                                })
                                .catch(error => {
                                    observer.error(error);
                                });
                        });
                    case 'auth_access_token_invalid':
                    case 'auth_refresh_token_invalid':
                    case 'auth_refresh_token_expired':
                        // @ts-ignore
                        response.errors = null;
                        reSignIn(true);

                        break;
                }
            }
        }

        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }
    }
);


const authLink = setContext((_, { headers }) => {
    const authToken = Storage.getAuthToken();
    return {
        headers: {
            ...headers,
            authorization: getAuthorizationBearer(authToken),
        }
    };
});

const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    reSignIn: {
                        read() {
                            return reSignIn();
                        }
                    }
                }
            }
        }
    }),
    link: ApolloLink.from([authLink, refreshTokenLink, httpLink]),
});



export default apolloClient