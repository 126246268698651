import { BrowserRouter, Route, Routes, useRouteError } from 'react-router-dom';
import MainLayout from '../pages/layouts/MainLayout';
import { Homepage } from '../pages/protected/Homepage';
import AccountPage from '../pages/protected/Settings/Account/AccountPage';
import ChangePasswordPage from '../pages/protected/Settings/ChangePassword/ChangePasswordPage';
import ProfilePage from '../pages/protected/Settings/Profile/ProfilePage';
import SettingsPage from '../pages/protected/Settings/SettingsPage';
import SubscriptionPage from '../pages/protected/Settings/Subscription/SubscriptionPage';
import TeamPage from '../pages/protected/Settings/Team/TeamPage';
import TagDetailPage from '../pages/protected/Tags/TagDetailPage';
import TagsListPage from '../pages/protected/Tags/TagsListPage';
import TodoListPage from '../pages/protected/Task/TaskListPage';
import TaskDetailPage from '../pages/protected/Task/TaskDetailPage';
import ForgotPasswordPage from '../pages/public/ForgotPasswordPage';
import ResetPasswordPage from '../pages/public/ResetPasswordPage';
import SignInPage from '../pages/public/SignInPage';
import SignUpPage from '../pages/public/SignUpPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { Paths } from './paths';

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<MainLayout />}>
          <Route
            // path={Paths.Home}
            index
            element={<Homepage />}
          />
          <Route
            path={Paths.TaskList}
            element={
              <PrivateRoute
                redirectPath="/"
                requiredPermissions={['manage-organization']}
              >
                {/** @ts-ignore **/}
                <TodoListPage />
              </PrivateRoute>
            }
          />
          <Route element={<TaskDetailPage />} path={Paths.TaskDetail} />
          <Route element={<TagDetailPage />} path={Paths.TagDetail} />
          <Route element={<TagsListPage />} path={Paths.Tags} />

          <Route element={<SettingsPage />} path={Paths.Settings}>
            {/* @ts-ignore */}
            <Route path="profile" index element={<ProfilePage />} />
            <Route path="password" element={<ChangePasswordPage />} />
            <Route path="team" element={<TeamPage />} />
            <Route path="subscription" element={<SubscriptionPage />} />
            <Route path="account" element={<AccountPage />} />
          </Route>
        </Route>
      </Route>
      <Route element={<PublicRoute />}>
        <Route element={<SignInPage />} path={Paths.SignIn}></Route>
        <Route element={<SignUpPage />} path={Paths.SignUp}></Route>
        <Route
          element={<ForgotPasswordPage />}
          path={Paths.ForgotPassword}
        ></Route>
        <Route
          element={<ResetPasswordPage />}
          path={Paths.ResetPassword}
        ></Route>
      </Route>

      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  </BrowserRouter>
);

export default Router;

export function TodosBoundary() {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
}
