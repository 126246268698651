import { Tabs } from '@mantine/core';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Page from '../../../components/page/Page';
import { useEffect } from 'react';

const SettingsPage = () => {
  const navigate = useNavigate();

  //const { tabValue } = useParams();
  const location = useLocation();

  console.log('location', location);

  const shouldRedirect = location.pathname === '/settings';

  let tabValue;
  tabValue = location.pathname.split('/').pop();

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/settings/profile');
    }
  });

  return (
    <Page title="Settings">
      <Tabs
        value={tabValue}
        onTabChange={(value) => navigate(`/settings/${value}`)}
      >
        <Tabs.List>
          <Tabs.Tab value="profile">Profile</Tabs.Tab>
          <Tabs.Tab value="password">Password</Tabs.Tab>
          <Tabs.Tab value="subscription">Subscription</Tabs.Tab>
          <Tabs.Tab value="team">Team</Tabs.Tab>
          <Tabs.Tab value="account">Account</Tabs.Tab>
        </Tabs.List>
      </Tabs>

      <Outlet />
    </Page>
  );
};

export default SettingsPage;
