import { ApolloProvider } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import AuthContextProvider from './context/AuthContextProvider';
import apolloClient from './lib/apolloClient';
import Router from './routes/Router';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

export default function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ primaryColor: 'indigo' }}
      >
        <AuthContextProvider>
          <ModalsProvider>
            {/* <Notifications> */}
            <Router />
            {/* </Notifications> */}
          </ModalsProvider>
        </AuthContextProvider>
      </MantineProvider>
    </ApolloProvider>
  );
}

export function Fallback() {
  return <p>Performing initial data load</p>;
}
