import * as Yup from 'yup';
import {
  TextInput,
  Text,
  Group,
  Button,
  Stack,
  Title,
  Space,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { Paths } from '../../routes/paths';
import FormBase, { FORM_MODE } from './FormBase';
import { useSendResetPasswordMutation } from '../../hooks/useForgotPasswordMutation';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Please enter an email address.'),
});

type ForgotPasswordFormProps = {
  onSubmit: (data: any) => void;
};

const ForgotPasswordForm = ({ onSubmit }: ForgotPasswordFormProps) => {
  return (
    <>
      <Title order={1} size="h3">
        Forgot Password?
      </Title>
      <Space h="md" />
      <Text>
        Type in your email address and we'll send you instructions to reset it.
      </Text>
      <Space h="sm" />

      <FormBase
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        submitAction={useSendResetPasswordMutation}
        mode={FORM_MODE.CREATE}
      >
        {(form) => (
          <>
            <Stack>
              <TextInput
                required
                label="Email"
                placeholder="Enter your email address"
                {...form.getInputProps('email')}
              />
            </Stack>

            <Group position="apart" mt="xl">
              <Text color="dimmed" size="xs">
                <Link to={Paths.SignIn}>Remember your password?</Link>
              </Text>

              <Button disabled={form.isSubmitting} type="submit">
                Reset password
              </Button>
            </Group>
          </>
        )}
      </FormBase>
    </>
  );
};

export default ForgotPasswordForm;
