import { Button, Group, Stack, TextInput, Textarea } from '@mantine/core';
import * as Yup from 'yup';
import { MUTATION_CREATE_TAG } from '../../../api/mutations';
import { QUERY_GET_TAGS, QUERY_GET_TASK_DETAIL } from '../../../api/queries';
import AsyncMultiselect from '../../../components/form/AsyncMultiselect';
import FormBase, { FORM_MODE } from '../../../components/forms/FormBase';
import { useCreateTaskMutation } from '../../../hooks/useCreateTaskMutation';
import { useUpdateTaskMutation } from '../../../hooks/useUpdateTaskMutation';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a title'),
  description: Yup.string().required('Please enter a description'),
});

const TaskForm = ({ mode, entityId, onSubmit }: any) => {
  return (
    <FormBase
      loader={QUERY_GET_TASK_DETAIL}
      queryVariables={{
        taskId: entityId,
      }}
      formatInitialValues={(data: any) => {
        return {
          ...data?.getTaskById,
          tags: data?.getTaskById?.tags?.map((item: any) => item.id),
        };
      }}
      onSubmit={onSubmit}
      submitAction={
        mode === FORM_MODE.CREATE
          ? useCreateTaskMutation
          : useUpdateTaskMutation
      }
      mode={mode}
      validationSchema={validationSchema}
    >
      {(form: any) => (
        <>
          <Stack>
            <TextInput
              required
              label="Title"
              placeholder="Enter the title of the task"
              {...form.getInputProps('title')}
            />
            <AsyncMultiselect
              loaderQuery={QUERY_GET_TAGS}
              createMutation={MUTATION_CREATE_TAG}
              placeholder="Select tags or create new ones"
              label="Tags"
              creatable
              clearable
              entityId={'createTag'}
              selectedValues={form?.values?.tags || []}
              formatData={(data: any) => {
                return data?.getTags?.edges?.map((item: any) => ({
                  value: item.node.id,
                  label: item.node.name,
                }));
              }}
              {...form.getInputProps('tags')}
            />

            <Textarea
              required
              label="Description"
              placeholder="Describe the task"
              {...form.getInputProps('description')}
            />
          </Stack>
          <Group position="right" mt="xl">
            <Button disabled={false} type="submit">
              {mode === FORM_MODE.CREATE ? 'Create task' : 'Save changes'}
            </Button>
          </Group>
        </>
      )}
    </FormBase>
  );
};

export default TaskForm;
