import {
  Avatar,
  Button,
  FileInput,
  Group,
  Space,
  Stack,
  TextInput,
} from '@mantine/core';

import { IconUpload, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import * as Yup from 'yup';
import { QUERY_GET_ME } from '../../../../api/queries';
import FormBase, { FORM_MODE } from '../../../../components/forms/FormBase';
import Section from '../../../../components/page/Section';
import { useUpdateProfileSettingsMutation } from '../../../../hooks/useUpdateProfileSettings';

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('Please enter a title'),
  lastname: Yup.string().required('Please enter a description'),
});

const ProfileForm = ({ mode, entityId, onSubmit }: any) => {
  const [previewFile, setPreviewFile] = useState(null);

  const handleFileChange = (file: any) => {
    if (file) {
      setPreviewFile(file);
    } else {
      setPreviewFile(null);
    }
  };

  return (
    <FormBase
      loader={QUERY_GET_ME}
      onSubmit={onSubmit}
      formatInitialValues={(data: any) => {
        return {
          ...data?.me,
        };
      }}
      submitAction={useUpdateProfileSettingsMutation}
      mode={FORM_MODE.EDIT}
      validationSchema={validationSchema}
    >
      {(form: any) => (
        <>
          <Stack spacing={'lg'}>
            <Section
              heading="Personal information"
              description="Change your personal information here."
            >
              <Stack maw={400}>
                <TextInput
                  required
                  label="First name"
                  placeholder="Enter the title of the task"
                  {...form.getInputProps('firstname')}
                />
                <TextInput
                  required
                  label="Last name"
                  placeholder="Describe the task"
                  {...form.getInputProps('lastname')}
                />

                <TextInput
                  disabled
                  readOnly
                  label="Email"
                  {...form.getInputProps('email')}
                />
              </Stack>
            </Section>
            <Section
              heading="Profile picture"
              description="Your picture should have one of the following endings: png, jpeg, gif, jpg."
            >
              <Stack maw={400}>
                <Group position="left" align="center">
                  <Avatar
                    src={
                      previewFile
                        ? URL.createObjectURL(previewFile)
                        : form.values.avatar
                    }
                    alt="Your profile pic"
                    size="lg"
                    mr={'xs'}
                  />
                  {form.values.avatar && !previewFile ? (
                    <Button
                      variant="subtle"
                      color="default"
                      leftIcon={<IconX size={16} />}
                      onClick={() => form.setFieldValue('avatar', null)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <FileInput
                      sx={{ flex: 1 }}
                      placeholder="Upload your photo"
                      accept={[
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'image/jpg',
                      ]}
                      clearable
                      {...form.getInputProps('avatar')}
                      onChange={(file) => {
                        handleFileChange(file);
                        form.setFieldValue('avatar', file);
                      }}
                      icon={<IconUpload size={14} />}
                    />
                  )}
                </Group>
              </Stack>
              <Space h="xl" />
              <Space h="xl" />
              <Button disabled={false} type="submit">
                Save changes
              </Button>
            </Section>
          </Stack>
        </>
      )}
    </FormBase>
  );
};

export default ProfileForm;
