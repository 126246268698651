import { useQuery } from '@apollo/client';
import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  SimpleGrid,
  Text,
} from '@mantine/core';
import {
  IconCheck,
  IconCoin,
  IconDiscount2,
  IconListCheck,
  IconListNumbers,
  IconReceipt2,
  IconUserPlus,
} from '@tabler/icons-react';
import { QUERY_GET_HOME_PAGE_STATS } from '../../api/queries';

const useStyles = createStyles((theme) => ({
  root: {
    //padding: theme.spacing.xl * 1.5,
  },

  value: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    color:
      theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.primaryColor,
  },

  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

const icons = {
  user: IconUserPlus,
  discount: IconDiscount2,
  receipt: IconReceipt2,
  coin: IconCoin,
};

interface StatsGridProps {
  data: {
    title: string;
    icon: keyof typeof icons;
    value: string;
    diff: number;
  }[];
}

export function Homepage() {
  const { classes } = useStyles();

  const {
    loading: fetchingStats,
    error: errorFetchingStats,
    data,
    refetch: refetchStats,
  } = useQuery(QUERY_GET_HOME_PAGE_STATS, {
    fetchPolicy: 'cache-and-network',
  });

  if (fetchingStats)
    return (
      <Box h="100%" pos={'relative'}>
        <LoadingOverlay visible overlayBlur={1} />
      </Box>
    );

  return (
    <div className={classes.root}>
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'xs', cols: 1 },
        ]}
      >
        {/* {stats} */}
        <Paper withBorder p="md" radius="md">
          <Group position="apart">
            <Text size="xs" color="dimmed" className={classes.title}>
              Open tasks
            </Text>
            {/* <Icon className={classes.icon} size={22} stroke={1.5} /> */}
            <IconListNumbers className={classes.icon} size={22} stroke={1.5} />
          </Group>

          <Group align="flex-end" spacing="xs" mt={25}>
            <Text className={classes.value}>{data.openTasks.totalCount}</Text>
          </Group>

          {/* <Text size="xs" color="dimmed" mt={7}>
            Compared to previous month
          </Text> */}
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group position="apart">
            <Text size="xs" color="dimmed" className={classes.title}>
              Closed tasks
            </Text>
            <IconCheck className={classes.icon} size={22} stroke={1.5} />
          </Group>

          <Group align="flex-end" spacing="xs" mt={25}>
            <Text className={classes.value}>{data.doneTasks.totalCount}</Text>
          </Group>

          {/* <Text size="xs" color="dimmed" mt={7}>
            Compared to previous month
          </Text> */}
        </Paper>
        <Paper withBorder p="md" radius="md">
          <Group position="apart">
            <Text size="xs" color="dimmed" className={classes.title}>
              Total tasks
            </Text>
            <IconListCheck className={classes.icon} size={22} stroke={1.5} />
          </Group>

          <Group align="flex-end" spacing="xs" mt={25}>
            <Text className={classes.value}>{data.totalTasks.totalCount}</Text>
          </Group>

          {/* <Text size="xs" color="dimmed" mt={7}>
            Compared to previous month
          </Text> */}
        </Paper>
      </SimpleGrid>
    </div>
  );
}

export default Homepage;
