import { forwardRef, useState } from 'react';
import {
  createStyles,
  Navbar,
  Group,
  Text,
  Avatar,
  Box,
  getStylesRef,
  UnstyledButton,
  Menu,
} from '@mantine/core';
import {
  IconSettings,
  IconLogout,
  IconListCheck,
  IconDashboard,
  IconTags,
  IconUserCircle,
  IconPhoto,
} from '@tabler/icons-react';
import useAuthContext from '../../hooks/useAuthContext';
import { Link, NavLink } from 'react-router-dom';
import { Paths } from '../../routes/paths';
import UserButton from '../common/UserButton';
//import { MantineLogo } from '@mantine/ds';

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getStylesRef('icon');
  return {
    background: {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[9],
    },

    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[8]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      padding: `8px 12px`,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[1]
          : theme.colors.gray[2],
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[8],
        color:
          theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[2],

        [`& .${icon}`]: {
          color:
            theme.colorScheme === 'dark' ? theme.white : theme.colors.gray[2],
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[2]
          : theme.colors.gray[4],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.colors.gray[8],
        color: theme.colors.gray[2],
        [`& .${icon}`]: {
          color: theme.colors.gray[4],
        },
      },
    },
  };
});

const data = [
  {
    link: Paths.Home,
    label: 'Dashboard',
    icon: IconDashboard,
  },
  {
    link: Paths.TaskList,
    label: 'Tasks',
    icon: IconListCheck,
  },
  {
    link: Paths.Tags,
    label: 'Tags',
    icon: IconTags,
  },
];

export function SideNavigation() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState('Home');

  const { signOut, getUserDetails } = useAuthContext();

  const userDetails = getUserDetails();

  const links = data.map((item) => {
    // @ts-ignore
    if (item.requiredPermissions) {
      // @ts-ignore

      const hasPermissions = item.requiredPermissions.every(
        (permission: any) =>
          userDetails && userDetails.permissions.includes(permission)
      );

      if (!hasPermissions) {
        return;
      }
    }

    return (
      <NavLink
        className={({ isActive }) =>
          cx(classes.link, {
            [classes.linkActive]: isActive,
          })
        }
        to={item.link}
        key={item.label}
      >
        <item.icon className={classes.linkIcon} stroke={1.5} />
        <span>{item.label}</span>
      </NavLink>
    );
  });

  return (
    <Navbar
      className={classes.background}
      height={'100%'}
      width={{ sm: 300 }}
      p="md"
    >
      <Navbar.Section grow>{links}</Navbar.Section>
      <NavLink
        className={({ isActive }) =>
          cx(classes.link, {
            [classes.linkActive]: isActive,
          })
        }
        to={Paths.Settings}
        key={'Settings'}
      >
        <IconSettings className={classes.linkIcon} stroke={1.5} />
        <span>Settings</span>
      </NavLink>

      <Navbar.Section className={classes.footer}>
        <Menu withArrow>
          <Menu.Target>
            <UserButton
              image={userDetails?.avatar || ''}
              plan={
                /*  @ts-ignore */
                userDetails?.plan ? `${userDetails?.plan} plan` : 'Free plan'
              }
              email={userDetails?.email || ''}
            />
          </Menu.Target>
          <Menu.Dropdown>
            {/* <Menu.Label>Application</Menu.Label> */}
            <Menu.Item
              icon={<IconLogout className={classes.linkIcon} stroke={1.5} />}
              onClick={(event) => {
                event.preventDefault();
                signOut();
              }}
            >
              Sign out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Navbar.Section>
    </Navbar>
  );
}
