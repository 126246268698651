import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Group,
  Space,
  Table,
  Text,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import useAuthContext from '../../../../hooks/useAuthContext';
import DateService from '../../../../utils/TimeService';
import { formatUserRole } from '../../../../utils/utils';

/* add typescript props interface */
const TeamMembersList = ({ members, onDeleteTeamMember }: any) => {
  const { getUserDetails } = useAuthContext();
  const userDetails = getUserDetails();
  if (!members) {
    return null;
  }

  const ths = (
    <tr>
      <th>Email</th>
      <th>Role</th>
      <th>Member since</th>
      <th></th>
    </tr>
  );

  const rows = members.map((member: any) => (
    <tr key={member.email}>
      <td width={300}>
        <Group align={'center'}>
          <Avatar size="md" src={member.avatar} />
          <Box component="div" color="gray">
            <Text>
              {member.firstname} {member.lastname}
            </Text>
            <Text fz={'sm'} color="gray">
              {member.email}
            </Text>
            <Space h="xs" />
            <Group spacing={'xs'} position="left">
              {member.isAccountOwner && (
                <Badge size="sm" variant="outline">
                  Owner
                </Badge>
              )}
              {member.id === userDetails?.id && (
                <Badge variant="gradient" size="sm">
                  You
                </Badge>
              )}
            </Group>
          </Box>
        </Group>
      </td>
      <td>{member.role && formatUserRole(member.role.name)}</td>
      <td>{DateService.getReadableDateTimeInNearPast(member.createdAt)}</td>
      <td>
        <Group spacing={0} position="right">
          {!member.isAccountOwner && member.id !== userDetails?.id && (
            <ActionIcon disabled={member.isAccountOwner}>
              <IconTrash
                onClick={() => onDeleteTeamMember(member.id)}
                size="1rem"
                color="black"
                stroke={1.5}
              />
            </ActionIcon>
          )}
        </Group>
      </td>
    </tr>
  ));

  return (
    <Table captionSide="bottom">
      <thead>{ths}</thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

export default TeamMembersList;
