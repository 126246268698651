import { Outlet } from 'react-router-dom';
import { AppShell } from '@mantine/core';
import useAuthContext from '../../hooks/useAuthContext';
import { SideNavigation } from '../../components/navigation/SideNavigation';
//import { MantineLogo } from '@mantine/ds';

/*
https://mantine.dev/core/app-shell/
*/

export function MainLayout() {
  const { getUserDetails } = useAuthContext();

  return (
    <AppShell
      padding="xl"
      navbar={<SideNavigation />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.white,
        },
      })}
    >
      {/* <Debug values={getUserDetails()} /> */}
      {/* Your application here */}
      <Outlet />
    </AppShell>
  );
}

export default MainLayout;
