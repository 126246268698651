import { ApolloError } from '@apollo/client';

export const getApiError = (
    error: ApolloError | undefined
): NetworkError | GraphQLError | null => {
    if (!error || (!error.graphQLErrors && !error.networkError)) {
        return null;
    }

    if (error.networkError) {
        return new NetworkError(error);
    }

    return new GraphQLError(error.graphQLErrors[0].extensions.code, error);
};

export class ApiError {
    protected code: string;
    protected message: string;
    protected error: {};

    constructor(code: string, message: string, error: Error) {
        this.code = code;
        this.message = message;
        this.error = error;
    }

    getCode() {
        return this.code;
    }

    getMessage() {
        return this.message;
    }

    getError() {
        return this.error;
    }
}

type GraphQLExtensions = {
    missingPermission?: string,
}

type InvalidInputArgs = {
    [key: string]: {
        [key: string]: string;
    };

}

export class GraphQLError extends ApiError {
    protected inputErrors: InvalidInputArgs = {};
    protected extensions: GraphQLExtensions;

    constructor(code: string | any, error: ApolloError) {
        super(code, error.graphQLErrors[0].message, error);

        this.extensions = error.graphQLErrors[0].extensions || {};

        if (code === 'BAD_USER_INPUT') {
            this.inputErrors = error.graphQLErrors[0].extensions.invalidInputArgs as InvalidInputArgs;
        }

    }

    hasInputErrors(): boolean {
        return this.inputErrors && Object.keys(this.inputErrors).length !== 0;
    }

    getInputErrors(): any {
        return this.inputErrors;
    }

    getExtensions(): GraphQLExtensions {
        return this.extensions;
    }
}

export class UnknownError extends ApiError {
    constructor(error: Error) {
        super('unknown_error', error.message, error);
    }
}

export class NetworkError extends ApiError {
    constructor(error: Error) {
        super('network_error', error.message, error);
    }
}

