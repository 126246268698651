import { ApolloError } from '@apollo/client';
import { Box } from '@mantine/core';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SignUpForm from '../../components/forms/SignUpForm';
import { useAcceptInvitationMutation } from '../../hooks/useAcceptInvitationMutation';
import { useSignUpMutation } from '../../hooks/useSignUpMutation';
import ErrorService from '../../services/ErrorService';

const SignUpPage = () => {
  const [signUp, { loading }] = useSignUpMutation();
  const [acceptInvitation, { accepting }] = useAcceptInvitationMutation();
  const [formSubmitErrors, setFormSubmitErrors] = useState({});

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const onSubmit = (data: any) => {
    if (!!token) {
      const _data = { user: { ...data.owner }, token };
      acceptInvitation(_data).catch((e: ApolloError) => {
        setFormSubmitErrors(ErrorService.getSubmitErrors(e));
      });
    } else {
      signUp(data).catch((e: ApolloError) => {
        setFormSubmitErrors(ErrorService.getSubmitErrors(e));
      });
    }
  };

  return (
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 420,
        paddingTop: 40,
      }}
    >
      <SignUpForm
        invitational={!!token}
        token={token}
        email={email}
        onSubmit={onSubmit}
        formSubmitErrors={formSubmitErrors}
      />
    </Box>
  );
};

export default SignUpPage;
