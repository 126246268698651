import { REFRESH_TOKEN } from "../api/mutations";
import Storage from "../services/Storage";
import apolloClient from "./apolloClient";

export const refreshAccessToken = () => {
    return apolloClient
        .mutate({
            mutation: REFRESH_TOKEN,
        })
        .then(response => response?.data?.refreshToken?.accessToken)
        .then(accessToken => {
            if (accessToken) {
                Storage.setAuthToken(accessToken);
            }

            return accessToken;
        });
};