import { Box, Grid, Group, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface SectionProps {
  heading?: string;
  description?: string;
  children?: ReactNode;
}

const Section: React.FC<SectionProps> = ({
  heading,
  description,
  children,
}) => {
  return (
    <Grid gutter="lg" align="flex-start" pt={'lg'}>
      <Grid.Col span={4}>
        <Text fw={600} fz="md">
          {heading}
        </Text>
        <Text color="grey" fw={400} fz="sm">
          {description}
        </Text>
      </Grid.Col>
      <Grid.Col span="auto">{children} </Grid.Col>
    </Grid>
  );
};

export default Section;
