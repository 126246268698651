import {
  Button,
  Group,
  PasswordInput,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Paths } from '../../routes/paths';
import FormBase, { FORM_MODE } from './FormBase';
import { useSignInMutation } from '../../hooks/useLoginMutation';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email address.'),
  password: Yup.string().min(
    8,
    'Your password should have at least 8 characters.'
  ),
});

const SignInForm = () => {
  return (
    <>
      <Title order={1} size="h3">
        Hello, who’s this?
      </Title>
      <Space h="md" />

      <FormBase
        validationSchema={validationSchema}
        mode={FORM_MODE.CREATE}
        submitAction={useSignInMutation}
        debug={false}
      >
        {(form: any) => (
          <>
            <Stack>
              <TextInput
                required
                label="Email"
                placeholder="hello@mantine.dev"
                {...form.getInputProps('email')}
              />
              <PasswordInput
                required
                id="password"
                label="Password"
                placeholder="Your password"
                {...form.getInputProps('password')}
              />

              <Text color="dimmed" size="xs">
                <Link to={Paths.ForgotPassword}>Forgot password?</Link>
              </Text>
            </Stack>
            <Group position="apart" mt="xl">
              <Text color="dimmed" size="xs">
                <Link to={Paths.SignUp}>
                  Don't have an account yet? Sign up!
                </Link>
              </Text>
              <Button disabled={form.submitting} type="submit">
                Sign in
              </Button>
            </Group>
          </>
        )}
      </FormBase>
    </>
  );
};

export default SignInForm;
