import { ApolloError, useApolloClient } from '@apollo/client';
import { Alert, Box, LoadingOverlay } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import ErrorService from '../../services/ErrorService';
import Debug from '../common/Debug';

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

function flattenErrorMessages(errors: any) {
  const flatErrors: { [key: string]: string } = {};

  for (const key in errors) {
    const error = errors[key];
    const message = ErrorService.getMessageForInputValidationErrorCode(error);
    flatErrors[key] = message;
  }

  return flatErrors;
}

type FormBaseProps = {
  validationSchema?: any;
  queryVariables?: any;
  mode: string;
  submitAction: () => Array<any>;
  onSubmit?: (values: any) => void;
  formatPreSubmit?: (data: any) => any;
  loader?: any;
  entityId?: string;
  debug?: boolean;
  entityKey?: string;
  disabled?: boolean;
  children: (values: any) => any;
  formatInitialValues?: (data: any) => any;
  initialValues?: any;
};

const FormBase = ({
  validationSchema,
  loader,
  formatInitialValues,
  formatPreSubmit,
  submitAction,
  onSubmit,
  entityKey,
  mode,
  entityId,
  debug = false,
  queryVariables,
  children,
  initialValues,
}: FormBaseProps) => {
  const [formSubmitErrors, setFormSubmitErrors] = useState({});
  const [fetching, setLoading] = useState(false);

  const client = useApolloClient();

  const [submit, { loading: submitting }] = submitAction();

  const prepareInitialValues = (result: any) => {
    if (mode === FORM_MODE.CREATE) {
      return initialValues || {};
    }

    if (result.data) {
      if (formatInitialValues && typeof formatInitialValues === 'function') {
        return formatInitialValues(result.data);
      }
    }
    return {};
  };

  useEffect(() => {
    if (mode === FORM_MODE.CREATE) {
      return;
    }

    setLoading(true);

    client
      .query({
        query: loader,
        variables: queryVariables,
      })
      .then((data) => {
        const _data = prepareInitialValues(data);
        form.setValues(_data);
        form.resetDirty(_data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: prepareInitialValues({}),
    validate: yupResolver(validationSchema),
  });

  const [formSubmitErrorMessage, setFormSubmitErrorMessage] = useState('');

  const onFormSubmit = (data: any) => {
    form.clearErrors();
    const formattedData = formatPreSubmit ? formatPreSubmit(data) : data;
    submit(formattedData)
      .then(({ data: result }: any) => {
        onSubmit && onSubmit(result);
      })
      .catch((e: ApolloError) => {
        const errors = ErrorService.getSubmitErrors(e);
        setFormSubmitErrors(errors);
      });
  };

  const handleFormSubmitErrors = (errors: any) => {
    const { errorMessage, inputErrors } = errors;
    setFormSubmitErrorMessage(errorMessage);
    form.setErrors(flattenErrorMessages(inputErrors));
  };

  useEffect(() => {
    form.clearErrors();
    setFormSubmitErrorMessage('');
    if (Object.keys(formSubmitErrors).length > 0) {
      handleFormSubmitErrors(formSubmitErrors);
    }
  }, [formSubmitErrors]);

  return (
    <Box pos={'relative'}>
      {formSubmitErrorMessage && (
        <Alert my={8} color="red" mt="md" icon={<IconAlertCircle />}>
          {formSubmitErrorMessage}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(onFormSubmit)}>
        <LoadingOverlay visible={fetching || submitting} overlayBlur={1} />
        {children(form)}
      </form>
      {debug && <Debug values={form.values} />}
    </Box>
  );
};

export default FormBase;
