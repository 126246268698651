import {
  ActionIcon,
  Button,
  Group,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import * as Yup from 'yup';
import FormBase, { FORM_MODE } from '../../../../components/forms/FormBase';
import { useInviteTeamMemberMutation } from '../../../../hooks/useInviteTeamMemberMutation';

const inviteeSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address must be valid')
    .required('Email is required'),
  role: Yup.string().oneOf(['Admin', 'Author']).required(),
});

//const validationSchema = Yup.array().of(inviteeSchema);
const validationSchema = Yup.object().shape({
  invitees: Yup.array().of(inviteeSchema),
});

const InviteTeamMemberForm = ({ entityId, onSubmit }: any) => {
  return (
    <FormBase
      onSubmit={onSubmit}
      submitAction={useInviteTeamMemberMutation}
      entityId={entityId}
      entityKey="me"
      mode={FORM_MODE.CREATE}
      validationSchema={validationSchema}
      initialValues={{
        invitees: [
          {
            email: 'john@example.com',
            role: 'Author',
          },
        ],
      }}
    >
      {(form: any) => {
        const rows = form.values.invitees?.map((invitee: any, index: any) => {
          return (
            <Group pos={'relative'} align="center" key={index}>
              <TextInput
                w={'50%'}
                label="Email"
                type="email"
                placeholder="Enter email address"
                {...form.getInputProps(`invitees.${index}.email`)}
              />
              <Select
                label="Role"
                w={'30%'}
                data={['Author', 'Admin']}
                placeholder="Select role"
                {...form.getInputProps(`invitees.${index}.role`)}
              />

              <ActionIcon
                pos="absolute"
                size={'lg'}
                bottom={2}
                right={4}
                disabled={form.values.invitees.length === 1}
                onClick={() => form.removeListItem('invitees', index)}
              >
                <IconTrash stroke={1.5} color="black" size="1rem" />
              </ActionIcon>
            </Group>
          );
        });
        return (
          <>
            <Stack spacing="sm">
              {rows}
              <Group position="apart" mt="md">
                <Button
                  variant="subtle"
                  leftIcon={<IconPlus size="1rem" />}
                  onClick={() =>
                    form.insertListItem('invitees', {
                      email: '',
                      role: 'User',
                    })
                  }
                >
                  Add more
                </Button>
                <Button type="submit">Send invitations</Button>
              </Group>
            </Stack>
          </>
        );
      }}
    </FormBase>
  );
};

export default InviteTeamMemberForm;
