import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_TASK } from '../api/mutations';

export const useUpdateTaskMutation = (): Array<any> => {
  const [updateTaskMutation, results] = useMutation(MUTATION_UPDATE_TASK, {});

  const updateTask = (data: any) => {
    const { title, id, description, tags } = data;
    return updateTaskMutation({
      variables: {
        data: {
          taskId: id,
          title,
          description,
          tags,
        },
      },
    });
  };

  return [updateTask, results];
};
