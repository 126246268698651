import { Box, Paper } from '@mantine/core';
import SignInForm from '../../components/forms/SignInForm';

const SignInPage = () => {
  return (
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 420,
        paddingTop: 40,
      }}
    >
      <Paper radius="md" p="xl" withBorder>
        <SignInForm />
      </Paper>
    </Box>
  );
};

export default SignInPage;
