import { gql, useMutation } from '@apollo/client';
import { MUTATION_UPDATE_PROFILE } from '../api/mutations';
import { QUERY_GET_ME } from '../api/queries';
import axios from 'axios';
import Storage from '../services/Storage';

export const useUpdateProfileSettingsMutation = (): Array<any> => {
  const [updateProfileMutation, results] = useMutation(
    MUTATION_UPDATE_PROFILE,
    {
      refetchQueries: [{ query: QUERY_GET_ME }],
    }
  );

  const updateProfile = async (data: any) => {
    const { lastname, firstname, avatar } = data;

    if (avatar instanceof File) {
      const formData = new FormData();
      formData.append('file', avatar);

      // add bearer token header to axios request
      return axios
        .post('http://localhost:4000/media', formData, {
          withCredentials: true,
          headers: {
            'content-Type': 'multipart/form-data',
            authorization: `Bearer ${Storage.getAuthToken()}`,
          },
        })
        .then((response) => {
          return updateProfileMutation({
            variables: {
              data: {
                firstname,
                lastname,
                avatarId: response.data,
              },
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const data = {
        firstname,
        lastname,
        avatarId: avatar === null ? null : undefined,
      };

      return updateProfileMutation({
        variables: {
          data: {
            ...data,
          },
        },
      });
    }
  };

  return [updateProfile, results];
};
