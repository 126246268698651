import { useMutation, useQuery } from '@apollo/client';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Chip,
  Drawer,
  Group,
  Popover,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { IconSearch, IconTags, IconTrash } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  BULK_DELETE_TASKS,
  MUTATION_ADD_TAGS_TO_TASKS,
  MUTATION_CREATE_TAG,
  MUTATION_DELETE_TASK,
  MUTATION_TOGGLE_TASK_STATUS,
} from '../../../api/mutations';
import { QUERY_GET_TAGS, QUERY_GET_TASKS } from '../../../api/queries';
import AsyncMultiselect from '../../../components/form/AsyncMultiselect';
import { FORM_MODE } from '../../../components/forms/FormBase';
import Page from '../../../components/page/Page';
import DateService from '../../../utils/TimeService';
import TaskForm from './TaskForm';
import { Paths } from '../../../routes/paths';

export default function TaskListPage() {
  const listLength = 8;

  const navigate = useNavigate();

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebouncedState(
    '',
    200
  );

  const initialSearchState = {
    currentPage: 1,
    itemsPerPage: listLength,
    skip: 0,
    startCursor: undefined,
    status: undefined,
    endCursor: undefined,
    sortStatus: {
      direction: 'desc',
      columnAccessor: 'createdAt',
    },
    tagSelection: [],
    filterTagSelection: [],
    searchQuery: '',
  };

  useEffect(() => {
    setSearchState((prevState) => ({
      ...prevState,
      searchQuery: debouncedSearchQuery,
    }));
  }, [debouncedSearchQuery]);

  const [searchState, setSearchState] = useState(initialSearchState);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<any>([]);

  const {
    loading: fetchingTasks,
    error: errorFetchingTasks,
    data: tasks,
    refetch: refetchTasks,
  } = useQuery(QUERY_GET_TASKS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: listLength,
      after: undefined,
      before: undefined,
      skip: 0,
      status: undefined,
      searchQuery: '',
      last: undefined,
      orderBy: {
        direction: initialSearchState.sortStatus.direction,
        field: initialSearchState.sortStatus.columnAccessor,
      },
    },
    onCompleted: (data) => {
      setSearchState({
        ...searchState,
        startCursor: data.campaigns.pageInfo.startCursor,
        endCursor: data.campaigns.pageInfo.endCursor,
      });
    },
  });

  const [deleteTaskMutation, { loading: deleting }] = useMutation(
    MUTATION_DELETE_TASK,
    {
      refetchQueries: [
        {
          query: QUERY_GET_TASKS,
        },
      ],
    }
  );

  useEffect(() => {
    const { searchQuery, itemsPerPage, filterTagSelection, status } =
      searchState;

    const queryVariables = {
      first: itemsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
      query: searchQuery,
      status,
      tags: filterTagSelection.length ? filterTagSelection : undefined,
      orderBy: {
        direction: searchState.sortStatus.direction,
        field: searchState.sortStatus.columnAccessor,
      },
    };

    refetchTasks(queryVariables);

    // @ts-ignore
    setSearchState((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  }, [
    searchState.searchQuery,
    searchState.filterTagSelection,
    searchState.status,
  ]);

  const [addTagsToTasks, { loading: addingTags }] = useMutation(
    MUTATION_ADD_TAGS_TO_TASKS,
    {
      refetchQueries: [{ query: QUERY_GET_TASKS }],
    }
  );

  const [toggleTaskStatus, { loading: toggling }] = useMutation(
    MUTATION_TOGGLE_TASK_STATUS,
    {
      refetchQueries: [{ query: QUERY_GET_TASKS }],
    }
  );

  const [bulkDeleteMutation, { loading: bulkDeleting }] = useMutation(
    BULK_DELETE_TASKS,
    {
      refetchQueries: [{ query: QUERY_GET_TASKS }],
    }
  );

  const handleBulkDelete = async (selection: any) => {
    try {
      await bulkDeleteMutation({
        variables: {
          taskIds: selection.map((item: any) => item.id),
        },
      });

      await reloadTable();

      setSearchState((prevState) => ({
        ...prevState,
        currentPage: 1,
        tagSelection: [],
      }));

      setSelectedRecords([]);
      notifications.show({
        title: `Deleted tasks`,
        message: '🤥',
      });
    } catch (e) {
      console.log('Error');
    }
  };

  const onBulkDelete = () => {
    openConfirmModal({
      title: 'Delete tasks',
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete these tasks?</Text>
      ),
      labels: { confirm: 'Delete tasks', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => handleBulkDelete(selectedRecords),
    });
  };

  const handleSortStatusChange = async (sortStatus: any) => {
    const { itemsPerPage, searchQuery } = searchState;

    const queryVariables = {
      first: itemsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
      query: searchQuery,
      orderBy: {
        direction: sortStatus.direction,
        field: sortStatus.columnAccessor,
      },
    };

    refetchTasks(queryVariables);

    setSearchState((prevState) => ({
      ...prevState,
      sortStatus,
      currentPage: 1,
    }));
  };

  const handleStatusFilterChange = (status: any) => {
    let _status: any;
    switch (status) {
      case 'open':
        _status = 'open';
        break;
      case 'done':
        _status = 'done';
        break;
      default:
        _status = undefined;
    }

    // @ts-ignore
    setSearchState((prevState) => ({
      ...prevState,
      currentPage: 1,
      status: _status,
    }));
  };

  const handleBulkTagging = async () => {
    const tagIds = searchState.tagSelection.map((tag: any) => tag);

    if (!tagIds.length) return;

    const taskIds = selectedRecords.map((task: any) => {
      return task.id;
    });

    try {
      await addTagsToTasks({
        variables: {
          data: {
            tagIds,
            taskIds,
          },
        },
      });

      await reloadTable();

      setSearchState((prevState) => ({
        ...prevState,
        currentPage: 1,
        tagSelection: [],
        filterTagSelection: [],
      }));

      setSelectedRecords([]);
      // showNotification({
      //   title: `Tagged tasks`,
      //   message: '🤥',
      // });
    } catch (e) {
      console.log('Error:', e);
    }
  };

  const handlePaginationChange = async (targetPage: any) => {
    const { currentPage, searchQuery, itemsPerPage, endCursor, startCursor } =
      searchState;

    if (targetPage === currentPage) {
      return;
    }

    let paginationState = {};

    if (targetPage > currentPage) {
      // @ts-ignore
      paginationState.skip = itemsPerPage * (targetPage - 1 - currentPage);
      // Paginate forward
      // @ts-ignore
      paginationState.first = itemsPerPage;
      // @ts-ignore
      paginationState.after = endCursor;

      // @ts-ignore
      paginationState.last = undefined;
      // @ts-ignore
      paginationState.before = undefined;
    } else if (targetPage < currentPage) {
      // @ts-ignore
      paginationState.skip = itemsPerPage * (currentPage - (targetPage + 1));

      // Paginate backward
      // @ts-ignore
      paginationState.after = undefined;
      // @ts-ignore
      paginationState.first = undefined;

      // @ts-ignore
      paginationState.last = itemsPerPage;
      // @ts-ignore
      paginationState.before = startCursor;
    }

    const queryVariables = {
      ...paginationState,
      orderBy: {
        direction: searchState.sortStatus.direction,
        field: searchState.sortStatus.columnAccessor,
      },
      searchQuery,
    };

    try {
      await refetchTasks(queryVariables);

      setSearchState((prevState) => ({
        ...prevState,
        currentPage: targetPage,
      }));
    } catch (e) {
      console.log('Error');
    }
  };

  const reloadTable = async () => {
    await refetchTasks({
      first: listLength,
      after: undefined,
      before: undefined,
      skip: 0,
      searchQuery: '',
      last: undefined,
    });
  };

  const handleConfirmDeleteTask = async (taskId: any) => {
    try {
      const { data } = await deleteTaskMutation({
        variables: {
          taskId,
        },
      });

      //navigate('/todos', { replace: true });
      // showNotification({
      //   title: `Deleted task: ${data.deleteCampaign.title}`,
      //   message: '🤥',
      // });

      await refetchTasks({
        first: listLength,
        after: undefined,
        before: undefined,
        skip: 0,
        searchQuery: '',
        last: undefined,
      });
      setSearchState((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
    } catch (e) {
      console.log('Error');
    }
  };

  const onCreateTodo = ({ createTask }: any) => {
    const { id, title } = createTask;
    refetchTasks();
    navigate(`/tasks/${id}`);
    setDrawerIsOpen(false);
    // showNotification({
    //   title: `Created task: ${title}  `,
    //   message: '🤥',
    // });
  };

  const handleTaskStatusToggle = (taskId: string) => {
    toggleTaskStatus({
      variables: {
        taskId,
      },
    });
  };

  const onDeleteTask = (taskId: string) => {
    openConfirmModal({
      title: 'Delete task',
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this task?</Text>
      ),
      labels: { confirm: 'Delete task', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => handleConfirmDeleteTask(taskId),
    });
  };

  const onSelectedRecordsChange = (records: any) => {
    setSelectedRecords(records);
  };
  return (
    <Page
      title="Tasks"
      primaryAction={{
        content: 'Create task',
        onClick: () => setDrawerIsOpen(true),
        loading: fetchingTasks,
      }}
    >
      <Box
        p={'md'}
        sx={(theme) => ({
          borderRadius: 8,
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[7]
              : theme.colors.gray[1],
        })}
        mb={8}
      >
        <Group spacing={'lg'}>
          <TextInput
            label="Search"
            w={300}
            placeholder="Search for title or description"
            icon={<IconSearch size={16} />}
            defaultValue={searchState.searchQuery}
            onChange={(e) => setDebouncedSearchQuery(e.target.value)}
          />

          <AsyncMultiselect
            loaderQuery={QUERY_GET_TAGS}
            loaderQueryVariables={{ variables: { onlyInUse: true } }}
            createMutation={MUTATION_CREATE_TAG}
            placeholder="Select tags"
            label="Select tags"
            entityId={'createTag'}
            clearable
            selectedValues={searchState.filterTagSelection}
            formatData={(data: any) => {
              return data?.getTags?.edges?.map((item: any) => ({
                value: item.node.id,
                label: item.node.name,
              }));
            }}
            onChange={(tagSelection) => {
              // @ts-ignore
              setSearchState((prevState) => ({
                ...prevState,
                filterTagSelection: tagSelection,
              }));
            }}
          />
          <Select
            label="Status"
            placeholder="Pick one"
            defaultValue={'all'}
            onChange={handleStatusFilterChange}
            data={[
              { value: 'all', label: 'All' },
              { value: 'open', label: 'Open' },
              { value: 'done', label: 'Done' },
            ]}
          />
        </Group>
      </Box>
      <Box pos={'relative'}>
        {/* <LoadingOverlay visible={fetchingTasks} /> */}
        {selectedRecords.length > 0 && (
          <Box
            pos={'absolute'}
            sx={(theme) => ({
              zIndex: 10,
              background:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[7]
                  : theme.white,
            })}
            top={-3}
            right={0}
            p={4}
          >
            <Group position="right" spacing={'xs'}>
              <Text fz={'sm'} fw={'bold'}>
                {selectedRecords.length} selected
              </Text>

              <Popover
                width={300}
                position="bottom"
                withArrow
                closeOnEscape={false}
                shadow="md"
              >
                <Popover.Target>
                  <Button
                    size="xs"
                    variant="outline"
                    leftIcon={<IconTags size="1rem" stroke={1.5} />}
                  >
                    Add Tags
                  </Button>
                </Popover.Target>
                <Popover.Dropdown
                  sx={(theme) => ({
                    background:
                      theme.colorScheme === 'dark'
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}
                >
                  <Stack spacing="sm">
                    <AsyncMultiselect
                      loaderQuery={QUERY_GET_TAGS}
                      createMutation={MUTATION_CREATE_TAG}
                      placeholder="Select tags or create new ones"
                      label="Select tags"
                      clearable
                      creatable
                      entityId={'createTag'}
                      selectedValues={searchState.tagSelection}
                      formatData={(data: any) => {
                        return data?.getTags?.edges?.map((item: any) => ({
                          value: item.node.id,
                          label: item.node.name,
                        }));
                      }}
                      onChange={(tagSelection) => {
                        // @ts-ignore
                        setSearchState((prevState) => ({
                          ...prevState,
                          tagSelection,
                        }));
                      }}
                    />

                    <Button
                      size="xs"
                      variant="light"
                      onClick={() => handleBulkTagging()}
                      leftIcon={<IconTags size="1rem" stroke={1.5} />}
                    >
                      Add Tags
                    </Button>
                  </Stack>
                </Popover.Dropdown>
              </Popover>

              <Button
                size="xs"
                variant="outline"
                onClick={() => onBulkDelete()}
                leftIcon={<IconTrash size="1rem" stroke={1.5} />}
              >
                Delete
              </Button>
            </Group>
          </Box>
        )}
        <Box style={{ zIndex: 0 }}>
          <DataTable
            // withBorder
            records={tasks?.getTasks?.edges.map((edge: any) => edge.node)}
            fetching={fetchingTasks}
            onSortStatusChange={handleSortStatusChange}
            /**  @ts-ignore */
            sortStatus={searchState.sortStatus}
            columns={[
              {
                accessor: 'title',
                width: 200,
                title: 'Title',
                render: (item: any) => {
                  return (
                    <Link to={`${Paths.TaskList}/${item.id}`}>
                      {item.title}
                    </Link>
                  );
                },
              },
              { accessor: 'description', width: 250, title: 'Description' },
              {
                accessor: 'tags',
                title: 'Tags',
                width: 200,
                textAlignment: 'left',
                render: (item: any) => {
                  return item.tags?.map((tag: any) => {
                    return (
                      <Badge color={'gray'} variant="light">
                        {tag.name}
                      </Badge>
                    );
                  });
                },
              },
              {
                accessor: 'createdAt',
                title: 'Created',
                sortable: true,
                render: (item: any) => {
                  return (
                    <Text>
                      {DateService.getReadableDateTimeInNearPast(
                        item.createdAt
                      )}
                    </Text>
                  );
                },
              },
              {
                accessor: 'status',
                title: 'Status',
                render: (task: any) => {
                  return (
                    <Chip
                      size="xs"
                      checked={task.status === 'done'}
                      variant="light"
                      onChange={() => handleTaskStatusToggle(task.id)}
                    >
                      {task.status === 'done' ? 'Done' : 'Open'}
                    </Chip>
                  );
                },
              },
              {
                accessor: 'actions',
                title: 'Actions',
                textAlignment: 'right',
                render: (task: any) => (
                  <Group spacing={4} position="right" noWrap>
                    <ActionIcon
                      color="gray"
                      onClick={() => onDeleteTask(task.id)}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>
                ),
              },
            ]}
            totalRecords={tasks?.getTasks?.totalCount}
            recordsPerPage={searchState.itemsPerPage}
            page={searchState.currentPage}
            onPageChange={handlePaginationChange}
            selectedRecords={selectedRecords}
            minHeight={200}
            noRecordsText="No tasks found"
            onSelectedRecordsChange={onSelectedRecordsChange}
          />
        </Box>
      </Box>

      <Drawer
        opened={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        title="Add task"
        padding="xl"
        size="sm"
        position="right"
      >
        <TaskForm mode={FORM_MODE.CREATE} onSubmit={onCreateTodo} />
      </Drawer>
    </Page>
  );
}
