import * as Yup from 'yup';
import { PasswordInput, Text, Group, Button, Stack } from '@mantine/core';
import FormBase from './FormBase';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

type ResetPasswordFormProps = {
  onSubmit: (data: any) => void;
  isSubmitting: boolean;
  formSubmitErrors: any;
};

const ResetPasswordForm = ({
  onSubmit,
  isSubmitting,
  formSubmitErrors,
}: ResetPasswordFormProps) => {
  return (
    <>
      <Text size="lg" weight={500}>
        Reset your password
      </Text>
      <FormBase
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        formSubmitErrors={formSubmitErrors}
      >
        {(form) => (
          <>
            <Stack>
              <PasswordInput
                required
                label="Password"
                placeholder="hello@mantine.dev"
                {...form.getInputProps('password')}
              />
              <PasswordInput
                required
                label="Confirm Password"
                placeholder="hello@mantine.dev"
                {...form.getInputProps('confirmPassword')}
              />
            </Stack>
            <Group position="apart" mt="xl">
              <Button disabled={isSubmitting} type="submit">
                Change Password
              </Button>
            </Group>
          </>
        )}
      </FormBase>
    </>
  );
};

export default ResetPasswordForm;
