import { Box, Paper, Text } from '@mantine/core';
import { useState } from 'react';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  const [linkSuccessfullySent, setLinkSuccessfullySent] = useState(false);

  const onSubmit = () => {
    setLinkSuccessfullySent(true);
  };

  return (
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 435,
        paddingTop: 40,
      }}
    >
      <Paper radius="md" p="xl" withBorder>
        {linkSuccessfullySent ? (
          <Text>We´ve successfully sent you the instructions! </Text>
        ) : (
          <ForgotPasswordForm onSubmit={onSubmit} />
        )}
      </Paper>
    </Box>
  );
};

export default ForgotPasswordPage;
