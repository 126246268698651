import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuthContext from '../hooks/useAuthContext';
import { Paths } from './paths';
import { showNotification } from '@mantine/notifications';
import { reSignIn } from '../lib/apolloClient';

type Props = {
  children?: JSX.Element;
  redirectPath?: string;
  requiredPermissions?: string[] | null;
};

const PrivateRoute = ({
  children,
  redirectPath,
  requiredPermissions,
}: Props) => {
  const { isAuthenticated, getUserDetails, signOut } = useAuthContext();
  const userDetails = getUserDetails();
  let location = useLocation();

  if (reSignIn() && !isAuthenticated) {
    reSignIn(false);
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to={redirectPath || Paths.SignIn}
        state={{ from: location }}
        replace
      />
    );
  }

  if (requiredPermissions && userDetails) {
    const hasPermissions = requiredPermissions.every((permission) =>
      userDetails.permissions.includes(permission)
    );

    if (!hasPermissions) {
      return <Navigate to={redirectPath || Paths.Home} replace />;
    }
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
