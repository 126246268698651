import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_TAG } from '../api/mutations';

export const useUpdateTagMutation = (): Array<any> => {
  const [updateTagMutation, results] = useMutation(MUTATION_UPDATE_TAG, {});

  const updateTag = (data: any) => {
    const { name, id } = data;
    return updateTagMutation({
      variables: {
        id,
        name,
      },
    });
  };

  return [updateTag, results];
};
